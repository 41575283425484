import * as React from "react"
import '../css/main.css'
import '../css/bootstrap.min.css'
import '../css/animate.compat.css'
import '../css/boxicons.min.css'
import '../css/icofont.min.css'
import '../css/owl.carousel.min.css'

import Head from '../components/head'
import HeaderComponent from "../components/header.js"
import useScript from "../components/useScript"
import FooterComponent from "../components/footer"

// markup
const PrivacyPage = () => {
  
    useScript('static/js/jquery.min.js')
    useScript('static/js/bootstrap.bundle.js')
    useScript('static/js/venobox.min.js')
    useScript('static/js/jquery.waypoints.min.js')
    useScript('static/js/jquery.easing.min.js')
    useScript('static/js/counterup.min.js')
    useScript('static/js/owl.carousel.min.js')
    useScript('static/js/isotope.pkgd.min.js')
    useScript('static/js/aos.js')
    useScript('static/js/main.js')

  return (
    <>
      <Head title={'Privcacy Policy'} />
      <header id="header" className="fixed-top">
        <HeaderComponent />
      </header>
      <main id='main'>
        <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                <h2>Privacy Notice</h2>
                <ol>
                    <li><a href="/">Home</a></li>
                    <li>Privacy Notice</li>
                </ol>
                </div>
            </div>
        </section>   
        <section className="about" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 pt-4 pt-lg-0 text-justify">
                        <h3>Privacy Notice of Kvotum</h3>
                        <h5>The general objective and purpose of the processing of personal data</h5>
                        <p>
                        OU Kvotum, registration number 16089383, registered at Harju maakond, Kuusalu vald, Pudisoo küla, Männimäe/1, 74626, Estonia, (hereinafter – Kvotum) has developed this Privacy Notice to inform persons registered on the Kvotum website <a href="https://kvotum.com">https://kvotum.com</a> (hereinafter – Database) as its users (hereinafter – User) and who use the services provided by Kvotum (hereinafter – Services), on the collection, use and transfer of their personal data to third parties. In case of any questions or requests regarding the Privacy Notice of Kvotum or in case of request for any information regarding privacy issues, please contact Kvotum by writing an email to <a href="mailto:support@kvotum.com">support@kvotum.com</a>.
                        </p>
                        <p>
                        Kvotum ensures appropriate technical and organizational measures to protect the User’s data and to provide transparent data protection rules. This Privacy Notice outlines the data processing activities carried out by Kvotum. Kvotum processes all personal data in accordance with the Law on Personal Data Protection and other laws and normative acts of the Republic of Estonia. The personal data processing activities carried out by Kvotum can be described not only in this Privacy Notice, but also in the Terms of Kvotum Database User and Assignment Agreement (hereinafter – Agreement), Business Loan Assignment Agreement (hereinafter – Assignment Agreement) and the Cookie Policy of Kvotum. The terms used in the Privacy Notice conform to the terms used in the Agreement and the Assignment Agreement insofar as the Privacy Notice does not specify otherwise.
                        </p>
                        <h5>The User’s personal data processed</h5>
                        <p>
                        Kvotum processes several categories of User’s personal data, including, User’s identification data, data obtained in the process of KYC, contact information, User’s transaction data within the Database, communication data, cookie data and other information provided by the User.
                        </p>
                        <p>
                        Kvotum processes the following categories of User’s personal data:
                        </p>
                        <ul style={{listStyle: 'disc', paddingLeft: '3em'}}>
                        <li>
                            User’s data, including, but not limited to, the User’s first name and surname, gender, date of birth, place of birth, personal identity number or tax ID or investor’s ID number, information form identity document, copy of an identity document, photograph (hereinafter – User data);
                        </li>
                        <li>
                            Contact information, including the User’s address, country of residence, citizenship, e-mail address and phone number, IP address (hereinafter – Contact information);
                        </li>
                        <li>
                            Transaction data, including the User’s bought claims, transactions, payments, bank account, information regarding the concluded assignment agreements (hereinafter – Transaction data);
                        </li>
                        <li>
                            Communication data, including the User’s communication with Kvotum through the Database via live chat, via email, contact form, or other tools which could be introduced, as well as information from surveys and polls (hereinafter – Communication data); 
                        </li>
                        <li>
                            Other information the User has delivered to Kvotum, or which Kvotum has collected at our own initiative from the sources permitted by law (including via media and internet).
                        </li>
                        </ul>
                        <h5>Purposes of processing</h5>
                        <p>
                        Please be informed that if the User fails to provide the personal data when processing of such data is necessary to enter into and fulfil the Agreement or required by statute or law, Kvotum cannot provide the Services.
                        </p>
                        <h6>Use of cookies</h6>
                        <p>
                        The Database uses cookies to maintain and improve the operation of the Database. Information on the use of cookies is available at Special Cookies.
                        </p>
                        <h6>Provision of Services to the User</h6>
                        <p>
                        Upon submission of the Registration Application and registration with the Database, personal data of the User is used to enter into and fulfil both the Agreement and the Assignment Agreement, which includes, but is not limited to, identifying the User, creating the Account to be used as a means to receive the Services, and providing the Services. This means that the sub-purposes specified below and the subsequent data processing is intertwined, and one purpose cannot be reached without reaching the other purposes.
                        </p>
                        <h6>Identifying the User and concluding the agreements</h6>
                        <p>
                        In order to identify the User and conclude both the Agreement and the Assignment Agreement, the User data, Contact Information, Transaction data and Communication data is used. This data is necessary to verify the User’s identity, conclude and fulfil the Agreement and the Assignment Agreement, communicate with the User regarding the conclusion and fulfilment of the Agreement and the Assignment Agreement, validate the email provided by the User. The personal data processing is based on the conclusion and fulfilment of the Agreement and compliance with such legitimate interests of Kvotum as informing the User about the activity in the Account and protection of its rights and interests in case of a dispute as well as to comply with the statutory obligation to keep information on business activities conducted and to carry out due diligence if required by applicable law.
                        </p>
                        <h6>Creating and maintaining the Account</h6>
                        <p>
                        In order to create and maintain the Account, the User data, Contact Information, Transaction data and Communication data is used. This data is necessary to provide log in options in the Database, to communicate with the User in case of any inquiries and to ensure a means by which the User can receive the Services. The personal data processing is based on the conclusion and fulfilment of the Agreement and in compliance with such legitimate interests of Kvotum as maintenance and the development of the Account, informing the User about the activity in the Account and protection of its rights and interests in case of a dispute.
                        </p>
                        <h6>Providing Services</h6>
                        <p>
                        In order to provide and improve Services as per the Agreement and the Assignment Agreement, the User data, Contact Information and Transaction data is used. Other types of User’s personal data obtained from the User or created during the process of provision of Services can be processed as well, if necessary. This data is necessary to ensure payments’ processing and to provide any related services arising from Agreement and the Assignment Agreement, as well as to prevent fraud and abuse of Services. The personal data processing is based on the conclusion and fulfilment of the Agreement and the Assignment Agreement and compliance with such legitimate interests of Kvotum as informing the User about the activity in the Account and protection of its rights and interests in case of a dispute.              
                        </p>
                        <h6>Enforcement of statutory obligations</h6>
                        <p>
                        In order to fulfil the statutory obligations in the fields of accountancy and anti-money laundering and terrorist financing prevention, Kvotum processes the User data, Communication Data, Contact Information and other information specified by the User in accordance with the provisions of the laws and regulations of the Republic of Estonia.
                        </p>
                        <h6>KYC</h6>
                        <p>
                        As per the applicable laws and regulations and conditions of Kvotum internal control system Kvotum performs the KYC check of the User, which includes, but is not limited to identifying the User and verification of the User’s identity document and processing of the User data. As the KYC and identification of the User is carried out remotely, Kvotum has stricter obligations in relation to Users KYC check and can use a third-party verification service that might be used for User identification. Identification of the User and verification of the Users identification document may be carried out via a third-party verification service, which processes the Users data. During this process, the User submits a photo and/or video of the Users face and identification document to the verification portal using the specially designed system of the third-party verification service. This data processing is based on the necessity to conclude and perform the Assignment Agreement and to comply with the legal obligations of Kvotum in the field of anti-money laundering and terrorist financing prevention.
                        </p> 
                        <h6>Sending of informational materials</h6>
                        <p>
                        Upon applying for receipt of marketing and informational materials, including, promotional materials, the User agrees that User data, Contact Information, and certain Transaction data is used to prepare an appropriate commercial communication for the User based on the User’s data available to Kvotum. Thus, Kvotum can use User’ Contact information for sending commercial messages and messages on the proposed services of Kvotum or related enterprises, products, and various promotions and special offers. The User can individually opt out of receiving such communications. The User can withdraw consent for receipt of informational materials (such as newsletters, new product offers etc.) at any time by sending the email with the request to <a href="mailto:support@kvotum.com">support@kvotum.com</a>. Withdrawing consent for receiving informational materials does not affect the consent given to process information obtained through cookies.
                        </p> 
                        <h6>Service quality control</h6>
                        <p>
                        On the basis of the legitimate interest of Kvotum to improve and develop the quality of the Services, Kvotum, within the aim to evaluate, control and improve the quality of the Services, processes at least the Communication data when communicating with the User, for example, when conducting surveys and polls or resolving any issues in relation to the Services.
                        </p>
                        <h6>Compilation of statistical data</h6>
                        <p>
                        On the basis of the legitimate interest of Kvotum to compile statistical and analytical data regarding the Services used by the Users, to improve the Service quality and to develop Services, Kvotum, with the aim to analyze information related to the provision of the Services, processes summarized and anonymized information regarding the User data, Contact Information and Transaction data.                
                        </p>
                        <h5>The ways Kvotum collects information</h5>
                        <p>
                        To ensure the provision of information and Services, as well as to fulfil the obligations under applicable law, Kvotum collects information about the User in the following ways:
                        </p>
                        <ul style={{listStyle: 'disc', paddingLeft: '3em'}}>
                        <li>
                            Directly from the User or potential User of the Database by providing an opportunity to fill the Registration application and KYC forms on Database or by means of online communication by e-mail, chat or other communication channels and/or technical tools;
                        </li>
                        <li>
                            In an automated way from the User or potential User of the Database Kvotum collects technical information that may contain User IP address, device data, software data and similar data and cookies (more information available at Special Cookies);
                        </li>
                        </ul>
                        <p>
                        Kvotum can also acquire information about the User from the cooperation partners and affiliated companies.
                        </p>
                        <h5>Transfer of information to third parties</h5>
                        <p>
                        The User’s data is processed by Kvotum and our cooperation partners that provide servers, IT, legal, accounting, communication, e-mail validation, User verification and identification, CRM and intermediary services that facilitate integration with other services. Kvotum ensures that the said persons have undertaken not to divulge such information. 
                        </p>
                        <p>
                        Kvotum has a right to disclose the User’s data to the third-party platform(s), which is authorised to display the loans for sale on the Database for acquiring the claims by the Users via the Database (hereinafter – Platform). Platform is responsible for facilitating the servicing of the loans and the acquired claims for the Users. The part of the User data, Transaction data and Contact Information is disclosed by Kvotum for the Platform to be able to connect the User to the acquired claim and to contact the User with on-boarding instructions to the Platform. Such information is provided to the Platform only in case of successful acquisition of the claim by the User. 
                        </p>
                        <p>
                        Kvotum has right to disclose the User’s data to any person related to the fulfilment of commitments arising to Kvotum from the Agreement and Assignment Agreement (including to communications service providers, payment intermediaries, credit institutions, IT service providers, marketing service providers etc.) and to outsourced service providers that Kvotum has engaged in the provision of services arising from the Agreement and Assignment Agreement, insofar as such information is necessary for the performance of functions delegated to them;
                        </p>
                        <p>
                        In the cases specified by law, Kvotum is obligated to disclose the User’s personal data to state authorities. Kvotum does not disclose any personal data to state authorities or third parties without a legal basis.
                        </p>
                        <h5>Receipt of information from third parties</h5>
                        <p>
                        As stated before, Kvotum can and under certain circumstances has the obligation to obtain information about the User from third parties for reasons related to Service provision (for example, to verify User’s email or to ensure proper payment identification), customer KYC check, legal obligations of Kvotum in the field of anti-money laundering and terrorist financing prevention. Kvotum can obtain or receive information about the User from payment institutions, public and private databases, social networks, and other sources.
                        </p>
                        <p>
                        For the aforementioned purposes we can request and obtain information from financial and payment institutions, including banks and from our cooperation partners regarding the provision of payments, technical support or services.
                        </p>
                        <p>
                        If information received from the User or about the User does not identify the User as a natural person and/or is used in an anonymized or otherwise unidentifiable manner, we may use it for any purposes other than those previously mentioned, including transfer to third parties.
                        </p>
                        <h5>The information storage and security</h5>
                        <p>
                        The data that Kvotum collects from the User is to be transferred to and stored at a destination inside the EEA. All information the User provides to Kvotum is stored securely on the servers of Kvotum or servers of our partners and service providers.
                        </p>
                        <p>
                        Kvotum highly values the security and confidentiality of the User data. Kvotum uses the latest technology to maximize the protection of the privacy and security of data of the User using special protection methods and data encryption, but still can not guarantee 100% data security.
                        </p>
                        <p>
                        The access to the Users personal information within Kvotum is limited to those employees who have a good business reason to access or know this information. This is achieved through both technical solutions and physical access rights, as well as proper training and education of our employees who have built appropriate safeguards.
                        </p>
                        <h5>Duration of data processing</h5>
                        <p>
                        Kvotum stores and processes personal data for such a long time as it is established by normative regulations or for so long as is necessary for the rendering of service, in compliance with the established normative acts of responsibilities Kvotum for the storage of personal data, or for so long to ensure compliance with obligations of Kvotum as data manager and to perform the duties of clients' rights as data subjects prescribed by the law, for example, in respect of the storage and transfer of information regarding the persons who have received or otherwise processed the personal data of customers.
                        </p>
                        <h5>Special Cookies</h5>
                        <p>
                        In order to ensure Kvotum services the special Cookies are used to improve the experience of use of the Database and to provide its excellent functioning. Special Cookies are small text markers, which are stored by the program of accelerated network viewing on a computer or in mobile device of the User or potential User of the Database. Kvotum can use a special Cookie for such purposes, but only, to improve the functioning of the Database, statistics, saving of the User settings and others. The User can in his browsing or mobile Internet device to ban the special Cookie for all or specific websites, but separate functions for the use of the Database can be limited in this case. For further details please see Cookie Policy of Kvotum <a href="cookies.html">here</a>.
                        </p>
                        <h5>Access to own personal data</h5>
                        <p>
                        The User and potential User of the Database can get acquainted with the processing of his personal data, to obtain information about what information Kvotum stores and processes about him, from whom any of the relevant information is been obtained and to whom it is transferred. Such information is provided by Kvotum only to identified Users within thirty days after receipt of the request sent to the Kvotum email <a href="mailto:support@kvotum.com">support@kvotum.com</a>.
                        </p>
                        <p>
                        The User has the right to require that his personal data have been amended or corrected, as well as their processing shall be discontinued or they shall be destroyed, if the personal data are incomplete, outdated and unreliable. If the User can prove that the personal data are incomplete, outdated or unreliable, Kvotum immediately eliminates them. In this case, before applying the relevant demand the User is required to identify himself and prove his identity.
                        </p>
                        <h5>Changes in the privacy notice</h5>
                        <p>
                        Kvotum has the right to make changes to the privacy notice at any time by posting it on the Database and sending to the User by e-mail. The changes take effect from the date of its publication on the Database and sending to the User, and it replace all previous privacy notices.
                        </p>
                        <h5>Manager of the processing of personal data and its contact information</h5>
                        <p>
                        OU KVOTUM<br />Harju maakond, Kuusalu vald, Pudisoo küla, Männimäe/1, 74626, Estonia<br/><a href="mailto:support@kvotum.com">support@kvotum.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
      </main>
      <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
        <FooterComponent />
      </footer>
    </>
  )
}

export default PrivacyPage